import React from "react";
import "./topSection.css";
import CtaBtn from "../../components/ctaBtn/ctaBtn";
import TryAppBtn from "../../tryApp/tryappbtn";
import Countdown, { formatTimeDelta } from "react-countdown";
import Counter from "../../components/counter/counter";
import AddToBrowser from "../../components/addToBrowser/addToBrowser";

function TopSection({ openModal }) {
  return (
    <div>
      <div className="topSectionContainer">
        <div className="topSectionLeftPart">
          <h3 className="topSectionTitle">
            Your One-Click Solution for Easy CSS & HTML Extraction
          </h3>
          <br />
          <p className="topSectionSubTitle">
            Simplify your web design process and grab inspiration from any
            website; Hover over any element, check its CSS and HTML instantly,
            and copy with a single click.
          </p>
        </div>
        <div className="topSectionRightPart">
          <h3 className="lifetime-offer-banner">
            Lifetime license Limited Offer!
          </h3>
          {/* <h3 className="lifetime-offer-banner">
            Install Extension For Free Now!
          </h3> */}
          <div className="timerContainer">
            <Counter />
          </div>
          <CtaBtn price={"$20"} text2={"25% Off $15"} openModal={openModal} />

          <AddToBrowser />
          <TryAppBtn />
        </div>
      </div>
    </div>
  );
}

export default TopSection;

{
  /* <Countdown
              date={Date.now() + 100000000}
              intervalDelay={0}
              precision={3}
              formatTimeDelta
            /> */
}

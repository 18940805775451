import React from "react";
import "./addToBrowser.css";
import Chrome from "../../assets/chrome.png";

function AddToBrowser() {
  function newTab() {
    window.open(
      "https://chromewebstore.google.com/detail/forage-css/clhjnigmmhjjdajkhodnakldbfdkcgdj?authuser=0&hl=en",
      "_blank"
    );
  }
  return (
    <div onClick={newTab} style={{ cursor: "pointer" }}>
      <div class="browserbuttons-wrapper browserinherited-styles-for-exported-element">
        <div class="browserbutton browser-button browserbutton-principal first-browser-button">
          <img src={Chrome} alt="Chrome" />
          <span class="browser-text">{"Add to Chrome"}</span>
        </div>
      </div>
    </div>
  );
}

export default AddToBrowser;

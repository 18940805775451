// src/App.js
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import RefundPolicy from "./pages/legal/RefundPolicy";
import PrivacyNotice from "./pages/legal/PrivacyNotice";
import ServiceAgreement from "./pages/legal/ServiceAgreement";
import "./App.css";

function App() {
  // useEffect(() => {
  //   const Paddle = window.Paddle;
  //   Paddle.Environment.set("sandbox");
  //   Paddle.Initialize({
  //     token: "test_7d279f61a3499fed520f7cd8c08", // replace with a client-side token
  //   });
  // }, []);

  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/privacy-notice" element={<PrivacyNotice />} />
          <Route path="/service-agreement" element={<ServiceAgreement />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

import React from "react";
import ReactDOM from "react-dom";
import "./popup.css";

// const CustomModal = ({ isOpen, onClose, children, shadowRoot }) => {
//   if (!isOpen) return null;

//   // Access the shadow DOM root and find the react-root element within it
//   const modalRoot = shadowRoot.getElementById("react-root");
//   if (!modalRoot) {
//     console.error("Modal root element 'react-root' not found.");
//     return null;
//   }

//   return ReactDOM.createPortal(
//     <div className="custom-modal-overlay">
//       <div className="custom-modal">{children}</div>
//     </div>,
//     modalRoot
//   );
// };

// export default CustomModal;

const CustomModal = ({ isOpen, onClose, children, shadowRoot }) => {
  if (!isOpen) return null;

  return (
    <div className="custom-modal-overlay">
      <div className="custom-modal">{children}</div>
    </div>
  );
};

export default CustomModal;

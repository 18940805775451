import React from "react";
import ReactDOM from "react-dom";
import "./popup.css";

const CustomModal = ({ isOpen, onClose, children, shadowRoot }) => {
  if (!isOpen) return null;

  return (
    <div className="custom-modal-overlay">
      <div className="custom-modal">{children}</div>
    </div>
  );
};

export default CustomModal;

import React from "react";
import "./about.css";
import "../sections.css";
import Grid from "@mui/material/Grid";
import AboutCard from "./aboutCard";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import NearMeIcon from "@mui/icons-material/NearMe";

function AboutSection() {
  return (
    <div style={{ margin: "60px 0px" }}>
      <div className="sectionTitle">About Forage CSS</div>
      <div className="aboutContainer">
        <Grid container spacing={2} columns={{ xs: 3, md: 12 }}>
          <Grid item xs={4}>
            <AboutCard
              header={"The Ultimate Browser Extension for CSS Inspection"}
              sub={
                "Say goodbye to unnecessary, duplicated, overridden, or longhand CSS. Our tool performs hundreds of real-time advanced optimizations to make your code cleaner, shorter, and more efficient. Perfectly tailored to your preferences."
              }
              icon={<AutoFixHighIcon className="aboutCardIcon" />}
            />
          </Grid>
          <Grid item xs={4}>
            <AboutCard
              header={"Effortless and Rapid Inspection"}
              sub={
                "Understand your site's structure instantly without sifting through endless CSS rules in the browser's Dev Tools. Access all active styles immediately and complete your tasks faster. Utilize shortcuts for even quicker navigation and functionality."
              }
              icon={<FlashOnIcon className="aboutCardIcon" />}
            />
          </Grid>
          <Grid item xs={4}>
            <AboutCard
              header={"Copy Any Element Instantly"}
              sub={
                "Copying the CSS of any element is typically cumbersome. With our tool, simply click on an element, and its CSS is copied effortlessly. This includes all child elements, pseudo-classes, and media queries, helping you create your ideal webpage with ease."
              }
              icon={<NearMeIcon className="aboutCardIcon" />}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default AboutSection;

import css_beautify from "js-beautify";
import html_beautify from "js-beautify";
import prettify from "@liquify/prettify";

export function formatHTML(htmlCode) {
  // return html_beautify(htmlCode);
  const html = prettify.format(htmlCode);
  return html;
}

export function formatCSS(cssCode) {
  return css_beautify(cssCode);
}

export function formatCode(html) {
  var tab = "\t";
  var result = "";
  var indent = "";

  html.split(/>\s*</).forEach(function (element) {
    if (element.match(/^\/\w/)) {
      indent = indent.substring(tab.length);
    }

    result += indent + "<" + element + ">\r\n";

    if (element.match(/^<?\w[^>]*[^\/]$/) && !element.startsWith("input")) {
      indent += tab;
    }
  });

  return result.substring(1, result.length - 3);
}

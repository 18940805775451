import React from "react";
import "./whyus.css";

function WhyUs() {
  return (
    <div className="whyus-section">
      <div className="whyus-container">
        <h3 className="whyus-title">Why Us?</h3>
        <p className="whyus-text">
          We believe in making web tools that users love. Our mission is to
          provide the same exact features and functions you need — minus the
          fluff — all at a fraction of the price of similar tools.
          <br />
          <br />
          Forage CSS is just the beginning. We’re committed to creating a suite
          of extensions that deliver top-notch functionality without breaking
          the bank. Expect the same great features and more in future releases,
          always priced with your budget in mind.
        </p>
      </div>
    </div>
  );
}

export default WhyUs;

import React from "react";
import "./legal.css";

function RefundPolicy() {
  return (
    <div>
      <h1>Refund Policy</h1>
      <p>Effective Date: 5/25/2024</p>
      <p>
        We strive to ensure customer satisfaction. Please review our refund
        policy below.
      </p>
      <h2>One-Time License Purchases</h2>
      <p>
        Refunds are available within 14 days of purchase if you are not
        satisfied with the service.
      </p>
      <p>
        To request a refund, please contact us at contact@foragecss.click with
        your purchase details.
      </p>
      <h2>Monthly Subscriptions</h2>
      <p>You may cancel your subscription at any time.</p>
      <p>
        Refunds for monthly subscriptions are not provided, but you will
        continue to have access to the service until the end of the current
        billing period.
      </p>
      <h2>How to Request a Refund</h2>
      <p>
        Email us at contact@foragecss.click with your purchase details and
        reason for the refund request.
      </p>
      <p>
        Refunds will be processed within 7 business days of receiving your
        request.
      </p>
      <h2>Changes to this Refund Policy</h2>
      <p>
        We may update this refund policy from time to time. Any changes will be
        posted on our website.
      </p>
      <p>
        If you have any questions about this refund policy, please contact us at
        contact@foragecss.click.
      </p>
    </div>
  );
}

export default RefundPolicy;

import React from "react";
import logo from "../../assets/26.png";
import "./header.css";
import CtaBtn from "../headerCta/ctaBtn";
import AddToBrowser from "../addToBrowser/addToBrowser";

function Header({ openModal }) {
  return (
    <div className="header">
      <img src={logo} className="headerLogo" />
      <div className="headerCta">
        <AddToBrowser />
        <CtaBtn text1={"Buy Now for $15"} openModal={openModal} />
      </div>
    </div>
  );
}

export default Header;

// src/components/Footer.js
import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";

function Footer() {
  return (
    <div className="footer-section">
      <div className="footer-links">
        <Link to="/refund-policy">Refund Policy</Link>
        <Link to="/privacy-notice">Privacy Notice</Link>
        <Link to="/service-agreement">Service Agreement</Link>
      </div>
      <div className="footer-links">contact us: contact@foragecss.click</div>
    </div>
  );
}

export default Footer;

import React, { useState } from "react";
import TopSection from "../sections/topSection/topSection";
import ProductDetails from "../sections/productGuide/productGuide";
import Header from "../components/header/header";
import Pricing from "../sections/pricing/pricing";
import Footer from "../components/footer/footer";
import TryBtn from "../tryApp/tryappbtn";
import PaymentPopup from "../components/paymentPopup/paymentPopup";
import DemoSection from "../sections/demoSection/demoSection";
import Faqs from "../components/faqs/faqs";
import AboutSection from "../sections/about/about";
import WhyUs from "../sections/whyus/whyus";

function Home() {
  const [paymentModal, setPaymentModal] = useState(false);

  const openModal = () => setPaymentModal(true);
  const closeModal = () => setPaymentModal(false);

  return (
    <div>
      <Header openModal={openModal} />

      <TopSection openModal={openModal} />
      <WhyUs />
      <DemoSection />
      <AboutSection />
      <ProductDetails />
      <Pricing openModal={openModal} />
      <Faqs />
      <Footer />
      <PaymentPopup paymentModal={paymentModal} closeModal={closeModal} />
    </div>
  );
}

export default Home;

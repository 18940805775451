import React, { useEffect, useState, useRef } from "react";
import "./contentApp.css";
import "./main.css";
import "./popups/codePopup.css";
import "./App.css";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";
import ActivePopup from "./popups/active_popup";
import Popover from "@mui/material/Popover";
import { createPortal } from "react-dom";
import { html as beautifyHtml, css as beautifyCss } from "js-beautify";

//       "css": ["/static/css/content_script.css"],
//"run_at": "document_end"
///    "file:///*"

/////ICONS
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";

import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import axios from "axios";
import { formatCSS } from "./prettify";
import { formatCode } from "./prettify";

import {
  extractAllCSSRules,
  extractElementsArr,
  joinCSSRules,
  matchesSelector,
} from "./functions/cssExtranctionFunction";
import { grabStyling } from "./functions/cssPopover";
import TryBtn from "../components/trybtn/trybtn";

function TryAppBtn({ shadowRoot }) {
  //
  // Activate App
  const [activeApp, setActiveApp] = useState(false);
  const [authorized, setAuthorized] = useState(true);
  const [modalOpen, setOpenModal] = useState(false);
  const [btnPosition, setBtnPosition] = useState(false);
  const [snippingActive, setSnippingActive] = useState(false);
  const [selectedElements, setSelectedElements] = useState([]);

  //// set CSS/HTML
  const [cssCode, setCSSCode] = useState("");
  const [htmlCode, setHTMLCode] = useState("");
  const [copiedHTML, setCopiedHTML] = useState(false);
  const [copiedCSS, setCopiedCSS] = useState(false);

  const context = {
    window,
    document,
  };

  const setContext = (window) => {
    context.document = window.document;
    // @ts-expect-error
    context.window = window;
  };

  function toggleBtnPosition() {
    btnPosition ? setBtnPosition(false) : setBtnPosition(true);
  }

  function togglePlayBtn() {
    alert("it started");
    snippingActive ? setSnippingActive(false) : setSnippingActive(true);
  }

  const openModal = () => setOpenModal(true);

  const closeModal = () => {
    setOpenModal(false);
    clearHighlights();
    setSelectedElements([]);
  };

  useEffect(() => {
    // Set up event listeners for element highlighting and selection
    if (snippingActive) {
      document.addEventListener("mouseover", handleMouseOver, true);
      document.addEventListener("mouseout", handleMouseOut, true);
      document.addEventListener("click", handleElementSelection, true);
    } else {
      document.removeEventListener("mouseover", handleMouseOver, true);
      document.removeEventListener("mouseout", handleMouseOut, true);
      document.removeEventListener("click", handleElementSelection, true);
    }

    return () => {
      document.removeEventListener("mouseover", handleMouseOver, true);
      document.removeEventListener("mouseout", handleMouseOut, true);
      document.removeEventListener("click", handleElementSelection, true);
    };
  }, [snippingActive]);

  const handleMouseOver = (event) => {
    if (snippingActive && !event.target.className.includes("captureBtn")) {
      event.target.classList.add("hover-highlight");
      const targetElement = event.target;
    }
  };

  const handleMouseOut = (event) => {
    if (snippingActive && !event.target.className.includes("captureBtn")) {
      event.target.classList.remove("hover-highlight");
    }
  };

  const handleElementSelection = (event) => {
    if (!snippingActive || event.target.className.includes("captureBtn")) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    const targetElement = event.target;
    targetElement.classList.remove("hover-highlight");

    let newSelection;
    clearHighlights();
    setSelectedElements([]);
    newSelection = [targetElement];
    setSelectedElements(newSelection);
    handleCaptureButtonClick(newSelection);
  };
  const clearHighlights = () => {
    document
      .querySelectorAll(".highlight-selected, .hover-highlight")
      .forEach((el) =>
        el.classList.remove("highlight-selected", "hover-highlight")
      );
  };

  function buildHTMLDocument(selection) {
    const htmlContent = selection.map((el) => el.outerHTML).join("");

    const formattedHtml = formatCode(htmlContent);
    console.log(formattedHtml);
    setHTMLCode(formattedHtml);

    return `
    <html>
        <body>
          <div id="my-extention-container">${htmlContent}</div>
        </body>
     </html>
     `;
  }

  const handleCaptureButtonClick = async (selection) => {
    setSnippingActive(false);
    const combinedHtml = buildHTMLDocument(selection);

    // Create an off-DOM iframe to hold the HTML content
    const iframe = document.createElement("iframe");
    iframe.style.position = "absolute";
    iframe.style.top = "-9999px";
    document.body.appendChild(iframe);

    iframe.srcdoc = combinedHtml;

    iframe.onload = async () => {
      // try {
      const iframeWindow = iframe.contentWindow;

      if (iframeWindow.document.readyState === "complete") {
        setContext(iframeWindow);
        const el =
          iframeWindow.document.querySelector("#my-extention-container") ||
          iframeWindow.document.body;
        const cssRes = extractCSSRules(el);
        if (cssRes) {
          openModal();
        } else {
          alert("no css found");
        }
      } else {
        console.error("Iframe document is not ready");
      }
      // }

      // catch (error) {
      //   console.error("Error with htmlToFigma:", error);
      // }
    };

    iframe.srcdoc = combinedHtml;
  };

  function formatCSS2(cssString) {
    return cssString
      .replace(/\s*;\s*/g, ";\n ")
      .replace(/\s*{\s*/g, " {\n ")
      .replace(/\s*}\s*/g, "\n}\n")
      .trim();
  }
  ///////////ORIGINAL
  function extractCSSRules(el) {
    const relevantCSS = [];
    const elSelectors = extractElementsArr(el);
    const cssRules = extractAllCSSRules();

    for (const css of cssRules) {
      if (matchesSelector(css.selector, elSelectors)) {
        relevantCSS.push(css);
      }
    }

    relevantCSS.forEach((css) => {
      return `${css.selector} {${css.style}}`;
    });

    const cssString = joinCSSRules(relevantCSS);
    const formatedCSS = formatCSS2(cssString);
    setCSSCode(formatedCSS);

    return cssString;
  }

  const codeBoxContainerStyle = {
    height: "300px",
    width: "100%",
  };

  const copyCode = (obj) => {
    navigator.clipboard
      .writeText(obj.code)
      .then(() => {
        obj.type === "css" ? setCopiedCSS(true) : setCopiedHTML(true);

        setTimeout(function () {
          obj.type === "css" ? setCopiedCSS(false) : setCopiedHTML(false);
        }, 2000);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <>
      <TryBtn togglePlayBtn={togglePlayBtn} />
      <ActivePopup
        modalOpen={modalOpen}
        closeModal={closeModal}
        copyCode={copyCode}
        cssCode={cssCode}
        htmlCode={htmlCode}
        copiedCSS={copiedCSS}
        codeBoxContainerStyle={codeBoxContainerStyle}
        copiedHTML={copiedHTML}
        setSnippingActive={setSnippingActive}
        shadowRoot={shadowRoot}
      />
    </>
  );
}

export default TryAppBtn;

import React from "react";
import "./productGuide.css";
import "../sections.css";
import ProductGuideCard from "../../components/productGuideCard/productGuideCard";
import Grid from "@mui/material/Grid";
import license from "../../assets/guide/license.png";
import extensionIcon from "../../assets/guide/chrome-icon.png";
import code from "../../assets/guide/code.png";
import code2 from "../../assets/guide/code2.png";
import controls from "../../assets/guide/controls.png";
// import copied from "../../assets/guide/copy.png";

function ProductDetails() {
  const guide1 =
    "press the Forage CSS icon to start the app, make sure the page is fully loaded";
  const guide2 = "Enter the license key that you received on you email";
  const guide3 =
    "control buttons will appear on the top right corner of the page, press the play button and you can select any element on the website to forage its css";
  const guide4 =
    "a popup will appear, here you can view the code and copy it with just one click";
  return (
    <div style={{ margin: "60px 0px" }}>
      <div className="sectionTitle">Product Guide</div>
      <div className="productDetailstContainer">
        <Grid container spacing={2} columns={{ xs: 6, md: 12 }}>
          <Grid item xs={6}>
            <ProductGuideCard
              number={1}
              description={guide1}
              image={extensionIcon}
              size={"40%"}
            />
          </Grid>
          <Grid item xs={6}>
            <ProductGuideCard number={2} description={guide2} image={license} />
          </Grid>
          <Grid item xs={6}>
            <ProductGuideCard
              number={3}
              description={guide3}
              image={controls}
              size={"40%"}
            />
          </Grid>
          <Grid item xs={6}>
            <ProductGuideCard
              number={4}
              description={guide4}
              image={code2}
              size={"70%"}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default ProductDetails;

import React, { useState, useEffect } from "react";
import "./paymentPopup.css";
import CustomModal from "../popup/popup";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { initializePaddle } from "@paddle/paddle-js";

function PaymentPopup({ paymentModal, closeModal }) {
  const [paddle, setPaddle] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // environment: "sandbox",
  // token: "test_60fed950fd98e5896013070be79",
  // live_316f663b449c87f98186be6ab16
  useEffect(() => {
    initializePaddle({
      token: "live_316f663b449c87f98186be6ab16",
      checkout: {
        settings: {
          displayMode: "overlay",
          theme: "light",
          locale: "en",
        },
      },
    }).then((paddleInstance) => {
      console.log("paddleInstance", paddleInstance.PricePreview);
      if (paddleInstance) {
        setPaddle(paddleInstance);
      }
    });
  }, []);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    closeModal();
    setIsLoading(false);
    // test: pri_01j05w1txkhxb5e24dfh3c77kg
    // live: pri_01j0nxpxzfke4sd1h9exg2eyq7
    paddle?.Checkout.open({
      disableQuantity: true,
      vatNumber: "",
      email: email,
      items: [
        {
          priceId: "pri_01j0nxpxzfke4sd1h9exg2eyq7",
        },
      ],
      eventCallback: async (data) => {
        if (data.name == "checkout.completed") {
          try {
            await axios.post(
              "https://foragecss-b1e454f64c73.herokuapp.com/generate_key",
              {
                name,
                email,
              }
            );
            setEmail("");
            setName("");
            alert("Payment Successful!");
            setShowPopup(true);
          } catch (error) {
            console.error("Error generating key:", error);
          } finally {
            setIsLoading(false);
          }
        }
      },
    });
    // console.log("info: ", name, email);
    // await axios
    //   .post("https://foragecss.uc.r.appspot.com/generate_key", {
    //     name,
    //     email,
    //   })
    //   .then((response) => {
    //     setEmail("");
    //     setName("");
    //     setShowPopup(true);
    //     setIsLoading(false);
    //     setEmailError(false);
    //   })
    //   .catch((error) => {
    //     setEmailError(true);
    //   });
  };

  // http://127.0.0.1:5000
  // await axios
  // .post("http://127.0.0.1:5000/generate_key", {
  //   name,
  //   email,
  // })
  // .then((response) => {
  //   setEmail("");
  //   setName("");
  //   setShowPopup(true);
  //   setIsLoading(false);
  // })
  // .catch((error) => {});

  return (
    <CustomModal isOpen={paymentModal} onClose={closeModal}>
      <div className="paymentfinfoform">
        {/* <h2>Simple Form</h2> */}

        <form onSubmit={handleSubmit}>
          {!showPopup ? (
            <>
              <div className="infoform__header">
                <button
                  className="infoform-exit"
                  onClick={() => {
                    closeModal();
                    setEmail("");
                    setName("");
                    setIsLoading(false);
                  }}
                >
                  &times;
                </button>
              </div>
              <div>
                <label>
                  Name:
                  <input
                    type="text"
                    value={name}
                    onChange={handleNameChange}
                    placeholder="Enter your name"
                    required
                  />
                </label>
              </div>
              <div>
                <label>
                  Email:
                  <input
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                    placeholder="Enter your email"
                    required
                  />
                </label>
              </div>
              <div className="infoformbtn-container">
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  <button
                    type="submit"
                    className="submit-button"
                    disabled={!name || !email}
                  >
                    Checkout
                  </button>
                )}
              </div>
            </>
          ) : (
            // <>
            //   {emailError ? (
            //     <>
            //       <h3>Error Occured!</h3>
            //       <p>
            //         If the error persists please contact us at
            //         contact@foragecss.click
            //       </p>
            //     </>
            //   ) : (
            <>
              <h3>Payment Successfull!</h3>
              <p>Please check your Email</p>
              <p style={{ fontSize: "12px" }}>
                Incase you didn't find it check junk mail
              </p>
              <button
                className="form-close-btn"
                onClick={() => {
                  setShowPopup(false);
                  closeModal();
                }}
              >
                Close
              </button>
            </>
          )}
          {/* </>
          )} */}
        </form>
      </div>
    </CustomModal>
  );
}

export default PaymentPopup;

// <div className="payment-resp">
//  <p>Form submitted successfully!</p>
//  <button onClick={() => setShowPopup(false)}>Close</button>
// </div>

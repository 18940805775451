import React from "react";
import "./pricing.css";
import "../sections.css";
import CtaBtn from "../../components/ctaBtn/ctaBtn";

function Pricing({ openModal }) {
  return (
    <div className="pricing-section">
      <div className="sectionTitle">Pricing</div>
      <div class="pricing-box-box">
        <div class="pricing-box">
          <h2>Lifetime License</h2>
          <p class="discounted-price">$15</p>
          <p class="main-price">$20</p>

          <ul class="benefits">
            <li>Access to all features</li>
            <li>Free updates</li>
            <li>24/7 Support</li>
            <li>No recurring fees</li>
          </ul>
          {/* <a href="/purchase" class="btn btn-primary">
            Get Lifetime Access
          </a> */}
          <CtaBtn text1={"Buy Now for 25% off $15"} openModal={openModal} />
        </div>
      </div>
    </div>
  );
}

export default Pricing;

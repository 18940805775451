import React from "react";
import "./trybtn.css";

function TryBtn({ togglePlayBtn }) {
  return (
    <div onClick={togglePlayBtn} style={{ cursor: "pointer" }}>
      <div class="trybuttons-wrapper tryinherited-styles-for-exported-element">
        <div class="trybutton try-button trybutton-principal first-try-button">
          <span class="text-try">{"Try Now on the Website"}</span>
        </div>
      </div>
    </div>
  );
}

export default TryBtn;

/////////////////////////////////////////////////////////////////////////////////
export function extractElementsArr(element, elementsInfo = []) {
  const tagName = element.tagName.toLowerCase();
  elementsInfo.push(tagName);

  // Extract the class name using getAttribute to handle all cases
  const className = element.getAttribute("class");
  if (className) {
    const classNameArr = className.split(" ");
    classNameArr.forEach((classItem) => elementsInfo.push(`.${classItem}`));
  }

  // Extract the ID using getAttribute
  const id = element.getAttribute("id");
  if (id) {
    elementsInfo.push(`#${id}`);
  }

  // Special case for Shadow DOM
  if (element.shadowRoot) {
    extractElementsArr(element.shadowRoot, elementsInfo);
  }

  // Traverse the children
  const children = element.children;
  for (let i = 0; i < children.length; i++) {
    extractElementsArr(children[i], elementsInfo);
  }

  // Remove duplicates and filter out unwanted entries
  const uniq = [...new Set(elementsInfo)].filter(
    (e) => e !== "#my-extension-container"
  );
  return uniq;
}

//////////////ORIGINAL
export function extractAllCSSRules(element) {
  const relevantSelectors = [];
  const cssRules = getAllCSSRules();

  for (let i = 0; i < cssRules.length; i++) {
    const rule = cssRules[i];

    // Split the rule string to separate selector and style
    const parts = rule.split("{");
    const selector = parts[0].trim();
    const style = parts[1]?.trim();

    if (selector && style) {
      relevantSelectors.push({
        selector: selector,
        style: style.slice(0, -1), // Remove the closing curly brace from style
      });
    }
  }
  return relevantSelectors;
}

function getAllCSSRules() {
  const cssRules = [];
  const styleSheets = document.styleSheets;

  for (let i = 0; i < styleSheets.length; i++) {
    try {
      const rules = styleSheets[i].cssRules;
      if (rules) {
        for (let j = 0; j < rules.length; j++) {
          cssRules.push(rules[j].cssText);
        }
      }
    } catch (error) {
      // Log the error and continue processing other stylesheets
      console.warn(
        `Cannot access rules from stylesheet ${styleSheets[i].href}:`,
        error
      );
    }
  }

  return cssRules;
}

function formatCSSRule(css) {
  return `${css.selector} {\n ${css.style.split(";").join(";\n ")}\n}\n`;
  // return `${css.selector.split(": ").join(":")} { ${"\n " + css.style.split(";").join(";\n")} }\n`;
}

export function joinCSSRules(relevantCSS) {
  return relevantCSS.map(formatCSSRule).join("\n");
}

///////////////ORIGINAL
export function matchesSelector(cssSelector, elSelectors) {
  // Loop through each element selector
  for (const elSelector of elSelectors) {
    // Split CSS selector into individual components
    const cssComponents = cssSelector.split(/[#.]/).filter(Boolean);
    console.log(3);
    // Check if the element selector matches the beginning of the CSS selector
    if (cssSelector.startsWith(elSelector)) {
      return true;
    }

    // Check if the CSS selector matches any element selector
    for (const cssComponent of cssComponents) {
      if (matchesComponent(cssComponent, elSelector)) {
        return true;
      }
    }
  }

  return false; // No match found
}

//////ORIGNAL
function matchesComponent(cssComponent, elSelectors) {
  // Check if the component is a tag name
  if (elSelectors.includes(cssComponent)) {
    return true;
  }

  // Check if the component is a class name
  if (
    cssComponent.startsWith(".") &&
    elSelectors.some((selector) => selector.startsWith("."))
  ) {
    const classNames = cssComponent.slice(5).split(".");
    console.log(4);
    if (classNames.every((name) => elSelectors.includes(`.${name}`))) {
      return true;
    }
  }

  // Check if the component is an ID
  if (
    cssComponent.startsWith("#") &&
    elSelectors.some((selector) => selector.startsWith("#"))
  ) {
    return elSelectors.includes(cssComponent);
  }

  return false; // Component doesn't match
}

import React from "react";
import "./about.css";
function AboutCard({ header, sub, icon }) {
  return (
    <div className="aboutCardContainer">
      {icon}
      <p className="aboutCardHeader">{header}</p>
      <p className="aboutCardSub">{sub}</p>
    </div>
  );
}

export default AboutCard;

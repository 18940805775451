import React from "react";
import "./legal.css";

function ServiceAgreement() {
  return (
    <div>
      <h1>Service Agreement</h1>
      <p>Effective Date: 5/25/2024</p>
      <p>
        Welcome to Forage CSS. By using our service, you agree to the following
        terms and conditions. Please read them carefully.
      </p>
      <h2>Service Description</h2>
      <p>
        Forage CSS is a Chrome extension that allows users to access HTML and
        CSS elements of any website.
      </p>
      <h2>License</h2>
      <p>
        One-Time License: A one-time payment grants you lifetime access to the
        Forage CSS extension.
      </p>
      <p>
        Monthly Subscription: A recurring monthly payment grants you access to
        the Forage CSS extension.
      </p>
      <h2>Payment</h2>
      <p>Payments are processed through our payment gateway, Paddle.</p>
      <p>
        By providing your payment information, you authorize us to charge the
        applicable fees.
      </p>
      <h2>User Obligations</h2>
      <p>
        You agree to use Forage CSS in compliance with all applicable laws and
        regulations.
      </p>
      <p>
        You must not misuse the service or use it for any illegal activities.
      </p>
      <h2>Termination</h2>
      <p>
        We reserve the right to suspend or terminate your access to Forage CSS
        if you violate any terms of this agreement.
      </p>
      <h2>Limitation of Liability</h2>
      <p>
        Forage CSS is provided "as is" without any warranties. We are not liable
        for any damages arising from the use of the service.
      </p>
      <h2>Changes to the Agreement</h2>
      <p>
        We may update this agreement from time to time. Any changes will be
        posted on our website, and it is your responsibility to review them.
      </p>
      <p>
        If you have any questions about this agreement, please contact us at
        contact@foragecss.click.
      </p>
    </div>
  );
}

export default ServiceAgreement;

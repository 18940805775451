import React from "react";
import "./legal.css";

function PrivacyNotice() {
  return (
    <div>
      <h1>Privacy Notice</h1>
      <p>Effective Date: 5/25/2024</p>
      <p>
        Your privacy is important to us. This privacy notice explains how we
        collect, use, and protect your information.
      </p>
      <h2>Information We Collect</h2>
      <p>Personal Information: Name and email address.</p>
      <p>Payment Information: Processed through our payment gateway, Paddle.</p>
      <h2>How We Use Your Information</h2>
      <p>To provide and improve our service.</p>
      <p>To process payments and manage subscriptions.</p>
      <p>To communicate with you regarding updates, promotions, and support.</p>
      <h2>Data Protection</h2>
      <p>
        We implement industry-standard security measures to protect your
        information.
      </p>
      <p>
        We do not sell or share your personal information with third parties
        except as required to provide our service or comply with legal
        obligations.
      </p>
      <h2>Your Rights</h2>
      <p>
        You have the right to access, correct, or delete your personal
        information.
      </p>
      <p>To exercise these rights, please contact us at contact@foragecss.click.</p>
      <h2>Changes to this Privacy Notice</h2>
      <p>
        We may update this privacy notice from time to time. Any changes will be
        posted on our website.
      </p>
      <p>
        If you have any questions about this privacy notice, please contact us
        at contact@foragecss.click.
      </p>
    </div>
  );
}

export default PrivacyNotice;

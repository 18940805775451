import React from "react";
import "./demoSection.css";
import video from "../../assets/Demo.mp4";

function DemoSection() {
  return (
    <div className="demoSectionContainer">
      <div className="sectionTitle">Demo</div>
      <div className="demoContainer">
        <video className="videoStyling" autoPlay loop muted>
          <source src={video} type="video/mp4" />
        </video>
      </div>
    </div>
  );
}

export default DemoSection;

import React from "react";
import "./productGuideCard.css";

function ProductGuideCard({ number, image, description, size }) {
  return (
    <div className="guideCardContainer">
      <div className="guideNumbering">{number}.</div>
      <img src={image} alt="" className="guideImg" style={{ width: size }} />
      <div className="guideDesc">{description}</div>
      <br />
    </div>
  );
}

export default ProductGuideCard;

import React from "react";
import "./faqs.css";

import Faq from "react-faq-component";
import ClickHere from "../../tryApp/clickhere";

const data = {
  rows: [
    {
      title: "Will this work on my website?",
      content: `Absolutely! Our tool is a browser extension, which means it works seamlessly on any website, irrespective of the frameworks, plugins, libraries, themes, or other tools you use. If you encounter any issues, please contact us, and we'll address them promptly.`,
    },
    {
      title: "How can I pause the scan?",
      content:
        'You can pause the scan by clicking the "Pause" button or simply pressing the Shift key.',
    },
    {
      title: "How long is the license valid? Is it a lifetime license?",
      content: `Yes, it's a lifetime deal (LTD). Purchase it once and enjoy unlimited use forever.`,
    },
    {
      title: "Can I use my license on multiple devices?",
      content: <p>Can I use my license on multiple devices?</p>,
    },
    {
      title: "Can I try it for free?",
      content: <ClickHere />,
    },
    {
      title: "Does it copy CSS pseudo-classes and pseudo-elements like?",
      content: (
        <p>
          Absolutely! It copies all CSS pseudo-classes and pseudo-elements,
          including :hover, :before, :after, :focus, etc
        </p>
      ),
    },
    {
      title: "Does it copy media queries?",
      content: (
        <p>
          Yes, it copies all media queries with a single click, saving you time
          and preserving the elements' responsiveness across different devices.
        </p>
      ),
    },
  ],
};

const styles = {
  // bgColor: 'white',
  titleTextColor: "#282c34",
  rowTitleColor: "#282c34",
  rowContentPaddingLeft: "50px",
  rowContentPaddingRight: "50px",
  // rowContentColor: 'grey',
  // arrowColor: "red",
};

const config = {
  animate: true,
  // arrowIcon: "V",
  tabFocus: true,
};

function Faqs() {
  return (
    <div className="faqSectionContainer">
      <div className="sectionTitle">Faq</div>
      <div className="faqContainer">
        <Faq data={data} styles={styles} config={config} />
      </div>
    </div>
  );
}

export default Faqs;

import React from "react";
import "./codePopup.css";
import "../main.css";

import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";

import Modal from "@mui/material/Modal";
import CustomModal from "../popup/popup";

function ActivePopup({
  modalOpen,
  closeModal,
  copyCode,
  cssCode,
  htmlCode,
  copiedCSS,
  copiedHTML,
  codeBoxContainerStyle,
  setSnippingActive,
  shadowRoot,
}) {
  return (
    <div>
      {/* <Modal
        open={modalOpen}
        closeOnDocumentClick={false}
        // container={modalRoot}
      > */}
      <CustomModal
        isOpen={modalOpen}
        onClose={closeModal}
        shadowRoot={shadowRoot}
      >
        <div className="captured-img-modal-container backgroundPopupStyle">
          <>
            <div className="modal__header">
              <button
                style={{
                  backgroundColor: "transparent",
                  color: "black",
                  border: "none",
                  outline: "none",
                  marginTop: "5px",
                  marginRight: "5px",
                }}
                onClick={() => {
                  closeModal();
                }}
              >
                &times;
              </button>
            </div>
            <div className="modal-container-bottom">
              <div className="code-container">
                <div className="code-container-header">
                  <h3>CSS</h3>

                  <button
                    className="copy-btn"
                    onClick={() => copyCode({ type: "css", code: cssCode })}
                  >
                    {copiedCSS ? "copied!" : "copy"}
                  </button>
                </div>

                <SyntaxHighlighter
                  language="css"
                  style={docco}
                  customStyle={{
                    overflow: "scroll",
                    margin: "0",
                    padding: "0",
                    width: "100%",
                    height: "280px",
                    borderRadius: "8px",
                    fontSize: "15px",
                    backgroundColor: "rgb(245 245 253)",
                  }}
                  className="code-container-box"
                >
                  {cssCode}
                  {/* {formatedCSS} */}
                </SyntaxHighlighter>
              </div>
              <div className="code-container">
                <div className="code-container-header">
                  <h3>HTML</h3>
                  <button
                    className="copy-btn"
                    onClick={() => copyCode({ type: "html", code: htmlCode })}
                  >
                    {copiedHTML ? "copied!" : "copy"}
                  </button>
                </div>
                <SyntaxHighlighter
                  language="html"
                  wrapLines={true}
                  style={docco}
                  customStyle={{
                    overflow: "scroll",
                    margin: "0",
                    padding: "0",
                    width: "100%",
                    height: "280px",
                    borderRadius: "8px",
                    fontSize: "15px",
                    backgroundColor: "rgb(245 245 253)",
                  }}
                  className="code-container-box"
                >
                  {htmlCode}
                  {/* {formatedHTML} */}
                </SyntaxHighlighter>
              </div>
              {/* <div className="code-container">
                <div className="code-container-header">
                  <h3>HTML</h3>
                  <button
                    className="copy-btn"
                    onClick={() => copyCode({ type: "html", code: htmlCode })}
                  >
                    {copiedHTML ? "copied!" : "copy"}
                  </button>
                </div>
                <SyntaxHighlighter
                  language="html"
                  style={docco}
                  customStyle={{
                    overflow: "scroll",
                    margin: "0",
                    padding: "0",
                    width: "100%",
                    height: "280px",
                    borderRadius: "8px",
                  }}
                  className="code-container-box"
                >
                  {htmlCode}
                </SyntaxHighlighter>
              </div>*/}
            </div>
          </>
        </div>
      </CustomModal>
      {/* </Modal> */}
    </div>
  );
}

export default ActivePopup;
